import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Social from './Social';
import Nav from './Nav';

export default class Footer extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <footer>
        <hr/>
        <Nav  />
      <div className="row">
        <div className="twelve columns">
         <Social resumeData={resumeData} />
        </div>
      </div>
     <div className="onesignal-customlink-container" />
    </footer>
    );
  }
}