import React, { Component, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { Link, animateScroll as scroll } from 'react-scroll';
import { RWebShare } from "react-web-share";

import Share from './Share';
  
const HeaderSmall = (props) => {
    
    const [scrolling, setScrolling] = useState(false);
    const [validPage, setValidPage] = useState(true);
    
    let sharePage = false;
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    
    const handleScroll = () => {
        if (window.scrollY >= 125) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
    
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
      
    let action_url = "";
    let action_title = "";
    let action_icon = "";
     
    if (page === "about") {
        action_url = "https://paypal.me/joshsroufe";
        action_title = "Send a Donation";
        action_icon = "fa-brands fa-paypal";
        //setValidPage(true);
    }
    else if (page === "resume" || page === "about") {
        action_url = "/files/josh_sroufe_resume.pdf";
        action_title = "Download Resume";
        action_icon = "fa fa-download";
        //setValidPage(true);
    }
    else if (page === "portfolio") {
        action_url = "/files/josh_sroufe_portfolio.pdf";
        action_title = "Download Portfolio";
        action_icon = "fa fa-download";
        //setValidPage(true);
    }
    else if (page === "contact") {
        action_url = "/files/joshsroufe.vcf";
        action_title = "Add to Contacts";
        action_icon = "fa fa-address-card";
        //setValidPage(true);
    }
    else if (page === "blog") {
        action_url = "https://medium.com/@joshsroufe";
        action_title = "View on Medium";
        action_icon = "fa-brands fa-medium";
        //setValidPage(true);
    }
    else if (page === "pulse-labs" || page === "house-canary" || page === "clear-capital" || page === "apple" || page === "miscellaneous"){
        action_url = "/";
        action_title = "Share";
        action_icon = "fa fa-share";
        sharePage = true; 
    }
    else if (page === "aegis-smart-home"){
        action_url = "/files/josh_sroufe_aegis_case_study.pdf";
        //action_title = "Share";
        action_title = "Download Case Study";
        action_icon = "fa fa-download";
        //sharePage = true; 
    }
    else {
        //setValidPage(false);
    }
      //console.log(sharePage, action_url);
    return (
    
    <section className={scrolling ? 'profile-info navbar-scroll' : 'profile-info'}>
      {action_url && sharePage ? <Share /> : <a href={action_url} target="_blank" className="header-action" title={action_title}><i className={action_icon}></i></a>}
      <div className="row">
        <div className="header-content">
          <Link to="/" ><img className="profile-pic" id="logo" src="/images/brand/js.svg" alt="Josh Sroufe" /></Link>
          <div className="details">
            <h2>{props.resumeData.name}</h2>
            <span>{validPage ? (section === "projects" || section === "case-study" ? page.replace(/-/g, ' ') : page) : "Page Not Found"} {section === "projects" || section === "case-study" ? section.replace(/-/g, ' ') : ""}</span>
          </div>
        </div>
      </div>
    </section>
    );

};

export default HeaderSmall;