import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';


import Close from './Close';
import Footer from './Footer';
import HeaderSmall from './HeaderSmall';

import ReactGA from 'react-ga4';
import moment from 'moment';

const Blog = (props) => {
  const [posts, setPosts] = useState([]);
  const getPostData = () => {
    axios
      .get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@joshsroufe")
      .then((res) => {
        setPosts(res.data.items);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  useEffect(() => {
    getPostData();
  }, []);
    
  function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }
    
  let resumeData = props.resumeData;
  
  ReactGA. send ({ 
      hitType: "pageview", 
      page: "/blog",
      title: "Blog",
    });
 
  console.log(posts);
  
  return (
      
      <div className="container">
        <Close />
        <HeaderSmall resumeData={resumeData} />
          <section id="blogs">
            <div className="row">
                <div className="twelve columns">

                    <div id="blog">
                    {posts.map((post, index) => (
                      <div key={post.guid} className="post">
                        <img src={post.description.toString().match(/<img[^>]+src="([^">]+)"/)[1]} />
                        <div className="title">
                            <h2>{post.title}</h2>
                            <span className="date">{moment(post.pubDate).format("MMM Do, YYYY")}</span>
                        </div>
                        <p> {removeTags(post.content.substring(0, 500))}... </p>
                        <a href={post.link} target="_blank" rel="noopener noreferrer">
                          Read more
                        </a>
                        <hr className={index === posts.length -1 ? "last" : ""} />
                      </div>
                    ))}
                  </div>
                </div>
            </div>
          </section>
      <Footer resumeData={resumeData} />
      </div>
      
  );
};
export default Blog;

//<p dangerouslySetInnerHTML={{ __html: post.content }} />