import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    
      
    return (
        <nav id="nav-wrap">
            <ul id="nav" className="nav">
               <li className={page === "about" ? "current" : ""}><Link to="/about">About</Link></li>
               <li className={page === "resume" ? "current" : ""}><Link to="/resume">Resume</Link></li>
               <li className={page === "portfolio" ? "current" : ""}><Link to="/portfolio">Portfolio</Link></li>
               <li className={page === "blog" ? "current" : ""}><Link to="/blog">Blog</Link></li>
               <li className={page === "contact" ? "current" : ""}><Link to="/contact">Contact</Link></li>
            </ul>
        </nav>
        );
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />