import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Close from './Close';
import Footer from './Footer';
import HeaderSmall from './HeaderSmall';

import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";

export default class Portfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
      
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/portfolio",
      title: "Portfolio",
    });
      
    return (
        
    <div className="container">
      <Close />
      <HeaderSmall resumeData={resumeData} />
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          {/*<h1>Check Out Some of My Works.</h1>*/}
          <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
          {
            resumeData.portfolio && resumeData.portfolio.map((item)=>{
              return(
                <div className="columns portfolio-item" key={item.name}>
                  <div className="item-wrap">
                    <Link to={"/" + item.description.replace(/\s+/g, '-').toLowerCase() + "/" + item.name.replace(/\s+/g, '-').toLowerCase()}>
                      <img src={item.imgurl} className={item.description === "Case Study" ? "item-img reverse" : "item-img" }/>
                      <div className="overlay">
                        <div className="portfolio-item-meta">
                          <h5>{item.name}</h5>
                          <p>{item.description} &bull; Updated {item.updated}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })
          }
          </div>
        </div>
      </div>
  </section>
<Footer resumeData={resumeData} />
</div>
        );
  }
}

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={false}
//    arrows={true}
//    infinite={true}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.portfolio && resumeData.portfolio.map((item)=>{
//  return(
//    <div className="portfolio-item" key={item.name}>
//          {/*<a href="#modal-01">*/}
//          <img src={item.imgurl} className="item-img"/>
//          <div className="overlay">
//            <div className="portfolio-item-meta">
//              <h5>{item.name}</h5>
//              <p>{item.description}</p>
//            </div>
//          </div>
//        {/*</a>*/}
//    </div>
//  )
//})
//}
//</Carousel>