import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    
      
    return (
        <Link to="/" className="close" title="Home"><i className="fa fa-home"></i></Link>
        );
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />