import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
      
    return (
       <Link to="/contact">
          <div className="freelancing">
            <span className={"status " + resumeData.availability}></span> <span className="availability">{resumeData.availability}</span> for Consulting
          </div>
       </Link>
        );
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />