import React from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import moment from 'moment';

export default () => {
  const { width, height } = useWindowSize();
    
    moment().format("MM/DD/YYYY");
    const now = moment(new Date());
    let today = moment().format("MMM Do");
    let born = moment('1984-11-12');
    let birthday = today == "Nov 12th";
    //birthday = today == today;
    let age = now.diff(born, 'years', true);
    
  return (
    birthday && <Confetti
      width={width}
      height={height}
      recycle={false}
      numberOfPieces={250}
    />
  )
};