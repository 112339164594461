import React, { Component, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import Header from './components/Header';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import PortfolioProject from './components/PortfolioProject';
import PortfolioStudy from './components/PortfolioStudy';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Blog from './components/Blog';
import PageNotFound from './components/PageNotFound';
import OneSignalInit from './components/OneSignal';

import ScrollToTop from './components/ScrollToTop';
import ConfettiTime from './components/ConfettiTime';
import ReactGA from 'react-ga4';

import resumeData from './resumeData';

class App extends Component {
    
  render() {
    ReactGA.initialize('G-EQ1Q3X4DJV');
      
    return (
      <div className="App adaptive">
            <OneSignalInit />
            <ConfettiTime />
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route exact path="/" element={<Header resumeData={resumeData} />} />
                    <Route path="/about" element={<About resumeData={resumeData} />} />
                    <Route path="/resume" element={<Resume resumeData={resumeData} />} />
                    <Route path="/portfolio" element={<Portfolio resumeData={resumeData} />} />
                    <Route path="/projects" element={<PortfolioProject resumeData={resumeData} />} />
                    <Route path="/projects/pulse-labs" element={<PortfolioProject resumeData={resumeData} />} />
                    <Route path="/projects/house-canary" element={<PortfolioProject resumeData={resumeData} />} />
                    <Route path="/projects/clear-capital" element={<PortfolioProject resumeData={resumeData} />} />
                    <Route path="/projects/apple" element={<PortfolioProject resumeData={resumeData} />} />
                    <Route path="/projects/miscellaneous" element={<PortfolioProject resumeData={resumeData} />} />
                    <Route path="/case-study/aegis-smart-home" element={<PortfolioStudy resumeData={resumeData} />} />
                    <Route path="/blog" element={<Blog resumeData={resumeData} />} />
                    <Route path="/contact" element={<ContactUs resumeData={resumeData} />} />
                    <Route path="*" element={<Header resumeData={resumeData} />} />
                </Routes>
            </BrowserRouter>
      </div>
    );
  }
}

export default App;
//<Header resumeData={resumeData}/>
//<About resumeData={resumeData}/>
//<ContactUs resumeData={resumeData}/>
//<Footer resumeData={resumeData}/>
//<Resume resumeData={resumeData}/>
//<Testimonials resumeData={resumeData}/>
//<Portfolio resumeData={resumeData}/>

//import {
//  BrowserRouter,
//  Routes,
//  Route,
//  Link,
//} from "react-router-dom";
//<Route path="/live" element={<Live />} />