import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
      
    return (
       <ul className="social-links">
            {
              resumeData.socialLinks && resumeData.socialLinks.map((item)=>{
                return(
                  <li key={item.className}>
                    <a href={item.url} target="_blank" title={item.name}>
                        <i className={item.className} />
                    </a>
                  </li>
                )
              })
            }
          </ul>
        );
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />