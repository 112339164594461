import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Close from './Close';
import Footer from './Footer';
import HeaderSmall from './HeaderSmall';

import moment from 'moment';
import ReactGA from 'react-ga4';

export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/resume",
      title: "Resume",
    });
      
    return (
        <div className="container">
        <Close />
        <HeaderSmall resumeData={resumeData} />
      <section id="resume">
        <div className="row work">
            <div className="three columns header-col">
               <h1><span>Work</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.work && resumeData.work.map((item) => {
                  
                  moment().format("MMM d YYYY");
                  const now = moment(new Date());
                  
                  let startFull = item.start.split(" ");
                  let endFull = item.end.split(" ");
                    
                    
                  let start = moment(startFull[0] + " 1 " + startFull[1]);
                  let end = moment(endFull[0] + " 1 " + endFull[1]);
                    
                  if (item.end === "Present") {
                      end = moment(now).format("MMM d YYYY");
                  }
                    
                  let duration = start.diff(end, 'years', true);
                    
                  duration = Math.abs(duration);
                  duration= Math.round(duration * 10) / 10
                  
                    
                  //console.log(end);
                    
                  return(
                    <div className="row item" key={item.CompanyName}>
                       <div className="twelve columns">
                          <div className="job-header">
                              <img src={item.logo} />
                              <div className="job-info">
                                  <h3>{item.CompanyName}</h3>
                                  <p className="info">
                                    {item.specialization} <span> &bull;</span> {item.location} <span> &bull;</span> <em className="date">{item.start} &ndash; {item.end} <span className="duration">({duration}y)</span></em>
                                  </p>
                              </div>
                          </div>
                          <p>{item.Achievements}</p>
                       </div>

                    </div>

                  )
                })
              }
            </div> 
         </div>
        <div className="row projects">

            <div className="three columns header-col">
               <h1><span>Projects</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.projects && resumeData.projects.map((item)=>{
                  return(
                    <div className="row item" key={item.name}>
                       <div className="twelve columns">
                          <h3>{item.name}</h3>
                          <p className="info">
                          {item.title}
                          <span> &bull;</span> <em className="date">{item.start} &ndash; {item.end}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>
                    </div>
                  )
                })
              }
            </div>
         </div>
         <div className="row education">

            <div className="three columns header-col">
               <h1><span>Education</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.education && resumeData.education.map((item)=>{
                  return(
                    <div className="row item" key={item.UniversityName}>
                       <div className="twelve columns">
                          <h3>{item.UniversityName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span> &bull;</span> <em className="date">{item.start} &ndash; {item.end}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>
                    </div>
                  )
                })
              }
            </div>
         </div>
        


         <div className="row skill">

            <div className="three columns header-col">
               <h1><span>Skills</span></h1>
            </div>

            <div className="nine columns main-col">

               <p className="skills-description">
               {resumeData.skillsDescription}
               </p>

   				<div className="bars">

   				   <ul className="skills">
                {
                  resumeData.skills && resumeData.skills.map((item) => {
                    return(
                      <li key={item.skillname}>
                      <span className={`bar-expand ${item.level.toLowerCase()}`}>
                      </span><em>{item.skillname}</em>
                      </li>
                    )
                  })
                }

   					</ul>

   				</div>

   			</div>

         </div>

      </section>
      <Footer resumeData={resumeData} />
      </div>
    );
  }
}