import React, { useState } from "react";
import { RWebShare } from "react-web-share";

const Share = () => {
    
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
    
  return (
    <div>
      <RWebShare
        data={{
          text: "Check this out from Josh Sroufe",
          url: url,
          title: page.replace(/-/g, ' ') + " " + section,
        }}
        onClick={() => console.log("shared successfully!")}
      >
          <a href="" className="header-action" title="Share"><i className="fa fa-share"></i></a> 
      </RWebShare>
    </div>
  );
};

export default Share;